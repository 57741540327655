<template>
  <div>
    <SubNav />
    <div v-if="blog" class="p-4 mt-32 mx-4 sm:mx-12">
      <!-- Back button -->
      <div class="flex  sm:items-center space-y-4 sm:space-y-0 space-x-4">
        <i class="fa fa-chevron-left text-navy border border-lightTangerine p-4 rounded-full" aria-hidden="true"></i>
        <router-link to="/blogs">
          <p class="font-grammatika text-lg sm:text-xl  mt-0 lg:mt-2 text-navy">Back to blogs</p>
        </router-link>
      </div>

      <!-- Blog Title and Episode -->
      <div class="flex flex-col sm:flex-row sm:items-center sm:space-x-2 mt-10">
        <h1 class="text-3xl sm:text-5xl text-navy font-rockinsoda">{{ blog.title }}</h1>
        <p class="text-3xl sm:text-5xl text-navy font-rockinsoda">{{ blog.episode }}</p>
      </div>

      <!-- Blog Date and Author -->
      <div class="flex flex-col sm:flex-row sm:items-center sm:space-x-2 mt-2">
        <p class="text-lg sm:text-2xl text-navy font-rockinsoda">{{ blog.date }} .</p>
        <p class="text-lg sm:text-2xl text-navy font-rockinsoda">{{ blog.author }}</p>
      </div>

      <!-- Blog Section -->
      <p class="font-grammatika text-sm sm:text-md p-2 w-auto sm:w-[28vh] mt-6 border-tangerine border -2 bg-lightTangerine">
        {{ blog.section }}
      </p>

      <!-- Blog Content -->
      <div>
        <p class="text-3xl sm:text-5xl text-navy mb-2 mt-16 font-rockinsoda">{{ blog.subtitle1 }}</p>
        <img :src="blog.image1" alt="Blog Cover" class="w-full h-auto sm:h-[500px] object-cover rounded-lg mt-12 mb-4" />
        <p class="text-base sm:text-lg text-gray-600 mt-8 font-grammatika text-justify">{{ blog.content1 }}</p>
      </div>

      <div>
        <p class="text-3xl sm:text-5xl text-navy mb-2 mt-16 font-rockinsoda">{{ blog.subtitle2 }}</p>
        <img :src="blog.image5" alt="Blog Cover" class="w-full h-auto sm:h-[500px] object-cover rounded-lg mt-12 mb-4" />
        <p class="text-base sm:text-lg text-gray-600 mt-8 font-grammatika text-justify">{{ blog.content4 }}</p>
      </div>

      <div>
        <p class="text-3xl sm:text-5xl text-navy mb-2 mt-16 font-rockinsoda">{{ blog.subtitle3 }}</p>
        <img :src="blog.image2" alt="Blog Cover" class="w-full h-auto sm:h-[500px] object-cover rounded-lg mt-12 mb-4" />
        <p class="text-base sm:text-lg text-gray-600 mt-8 font-grammatika text-justify">{{ blog.content2 }}</p>
      </div>

      <p class="font-grammatika text-sm sm:text-md p-2 w-auto sm:w-[28vh] mt-6 border-tangerine border -2 bg-lightTangerine">
        {{ blog.section2 }}
      </p>

      <div>
        <p class="text-3xl sm:text-5xl text-navy mb-2 mt-16 font-rockinsoda">{{ blog.subtitle4 }}</p>
        <img :src="blog.image3" alt="Blog Cover" class="w-full h-auto sm:h-[500px] object-cover rounded-lg mt-12 mb-4" />
        <p class="text-base sm:text-lg text-gray-600 mt-8 font-grammatika text-justify">{{ blog.content3 }}</p>
      </div>

      <p class="font-grammatika text-sm sm:text-md p-2 w-auto sm:w-[28vh] mt-6 border-tangerine border -2 bg-lightTangerine">
        {{ blog.section3 }}
      </p>

      <div>
        <p class="text-3xl sm:text-5xl text-navy mb-2 mt-16 font-rockinsoda">{{ blog.subtitle5 }}</p>
        <img :src="blog.image4" alt="Blog Cover" class="w-auto h-auto object-cover rounded-lg mt-12 mb-4" />
        <p class="text-base sm:text-lg text-gray-600 mt-8 font-grammatika text-justify">{{ blog.content5 }}</p>
      </div>
    </div>

    <div v-else class="p-4 text-navy font-grammatika">
      <p>Loading blog...</p>
    </div>

    <NewsLetter />
    <footerPage />
  </div>
</template>

  
  <script>
  import client from '../contentful';
  import SubNav from '@/components/SubNav.vue'; 
  import NewsLetter from '@/components/NewsLetter.vue';
  import footerPage from '@/components/footerPage.vue';
  export default {
    components: { SubNav,NewsLetter,footerPage },
    data() {
      return {
        blog: null, // Store individual blog data
      };
    },
    async created() {
      const blogId = this.$route.params.id; // Get blog ID from route params
      try {
        const response = await client.getEntries({ content_type: "blogPost" });
        const foundBlog = response.items.find((item) => item.sys.id === blogId);
        if (foundBlog) {
          this.blog = {
            title: foundBlog.fields.title || "Untitled",
            date:foundBlog.fields.date,
            category:foundBlog.fields.category,
            author:foundBlog.fields.author,
            episode:foundBlog.fields.episode,
            section:foundBlog.fields.section1,
            section2:foundBlog.fields.section2,
            section3:foundBlog.fields.section3,
            subtitle1:foundBlog.fields.subtitle1,
            subtitle2:foundBlog.fields.subtitle2,
            subtitle3:foundBlog.fields.subtitle3,
            subtitle4:foundBlog.fields.subtitle4,
            subtitle5:foundBlog.fields.subtitle5,
            subtitle6:foundBlog.fields.subtitle6,
            content1:foundBlog.fields.content1,
            content2:foundBlog.fields.content2,
            content3:foundBlog.fields.content3,
            content4:foundBlog.fields.content4,
            content5:foundBlog.fields.content5,
            content6:foundBlog.fields.content6,
            
           image: foundBlog.fields.blogImage 
              ? `https:${foundBlog.fields.blogImage.fields.file.url}` 
              : null,
            image1: foundBlog.fields.image1 
              ? `https:${foundBlog.fields.image1.fields.file.url}` 
              : null,
            image2: foundBlog.fields.image2
              ? `https:${foundBlog.fields.image2.fields.file.url}` 
              : null,
            image3: foundBlog.fields.image3
              ? `https:${foundBlog.fields.image3.fields.file.url}` 
              : null,
            image4: foundBlog.fields.image4
              ? `https:${foundBlog.fields.image4.fields.file.url}` 
              : null,
            image5: foundBlog.fields.image5
              ? `https:${foundBlog.fields.image5.fields.file.url}` 
              : null,

          };
        }
      } catch (error) {
        console.error("Error fetching blog details:", error);
      }
    },
  };
  </script>
  