<template>
  <div class="w-full bg-white">
    <SubNav/>

    <!-- Main Content Section -->
    <div class=" mt-2 md:mt-48 flex items-center justify-center">
      <div class="w-full md:max-w-4xl space-y-8">
        <div class="font-rockinsoda text-navy text-3xl sm:text-5xl md:text-6xl lg:text-7xl xl:text-8xl text-center mt-20 lg:mt-0">
          <p>NEWS & THE PRESS</p>
        </div>
      </div>
    </div>

    <!-- News Cards Section -->
    <div class="flex flex-col md:flex-row space-y-12 md:space-y-0 md:space-x-12 m-4 md:m-16">
      <!-- First Column -->
      <div class="flex flex-col w-full md:w-1/4 space-y-12">
        <!-- First Card -->
        <div class="bg-white">
          <div class="h-full flex flex-col">
            <h2 class="text-navy font-rockinsoda text-3xl sm:text-3xl md:text-4xl"><a href="https://www.ktpress.rw/2023/12/photos-innovation-and-entrepreneurship-celebrated-at-hanga-pitchfest-2023-finale/" target="_blank">PHOTOS: Innovation And
              Entrepreneurship Celebrated At Hanga Pitchfest 2023 Finale</a></h2>
            <p class="text-navy font-gramatika text-md sm:text-base mt-2">It was an evening of celebration and merrymaking as innovators and future entrepreneurs convened in Kigali...</p>
            <p class="text-navy font-rockinsoda text-md sm:text-base mt-4">Dec 23 . KT, edmund kagire</p>
          </div>
        </div>

        <!-- Second Card -->
        <div class="bg-white">
          <div class="flex flex-col space-y-8">
            <img src="../assets/news-1.jpg" class="h-[270px] object-cover">
            <div class="h-full flex flex-col">
              <h2 class="text-navy font-rockinsoda text-3xl sm:text-3xl md:text-4xl"><a href="https://www.newtimes.co.rw/article/12947/news/technology/hanga-pitchfest-2023-top-5-finalists-announced" target="_blank">Hanga Pitchfest 2023 top 5 finalists announced </a></h2>
              <p class="text-navy font-gramatika text-md sm:text-base mt-2">The top five finalists of the third edition of Hanga Pitchfest, Rwanda's largest tech startup competition...</p>
              <p class="text-navy font-rockinsoda text-md sm:text-base mt-4">Dec 23 . THE NEW TIMES, Patrick Nzabonimpa</p>
            </div>
          </div>
        </div>
        <!-- Third Card -->
        <div class="bg-white">
          <div class="flex flex-col space-y-8">
            <img src="../assets/news-2.jpg" class="h-[270px] object-cover">
            <div class="h-full flex flex-col">
              <h2 class="text-navy font-rockinsoda text-3xl sm:text-3xl md:text-4xl"> <a href="https://www.newtimes.co.rw/article/12970/news/technology/photos-rwandan-climate-tech-startup-wins-innovator-of-the-year-prize" target="_blank">PHOTOS: Rwandan climate tech startup wins innovator of the year prize </a></h2>
              <p class="text-navy font-gramatika text-md sm:text-base mt-2">Rwandan climate tech startup, Loopa, on Friday, December 8, emerged as the overall winner of Rwanda's...</p>
              <p class="text-navy font-rockinsoda text-md sm:text-base mt-4">Dec 23 . THE NEW TIMES, Edwin Ashimwe</p>
            </div>
          </div>
        </div>
      </div>

      <!-- Second Column -->
      <div class="flex flex-col w-full md:w-1/4 space-y-12">
        <!-- First Card -->
        <div class="bg-white">
          <div class="flex flex-col space-y-8">
            <img src="../assets/news-3.jpg" class="h-[270px] object-cover">
            <div class="h-full flex flex-col">
              <h2 class="text-navy font-rockinsoda text-3xl sm:text-3xl md:text-4xl"> <a href="https://igihe.com/amakuru/u-rwanda/article/miliyoni-110-frw-zahawe-urubyiruko-rwahize-urundi-muri-hanga-pitchfest-2023" target="_blank">Miliyoni 110 Frw zahawe urubyiruko rwahize urundi muri Hanga PitchFest 2023 </a></h2>
              <p class="text-navy font-gramatika text-md sm:text-base mt-2">Perezida Paul Kagame yashimiye ba rwiyemezamirimo batanu bahize abarenga 3000...</p>
              <p class="text-navy font-rockinsoda text-md sm:text-base mt-4">Dec 2 . IGIHE, Akayezu Jean de Dieu</p>
            </div>
          </div>
        </div>
        <!-- Second Card -->
        <div class="bg-white">
          <div class="flex flex-col space-y-8">
            <img src="../assets/news-4.jpg" class="h-[270px] object-cover">
            <div class="h-full flex flex-col">
              <h2 class="text-navy font-rockinsoda text-3xl sm:text-3xl md:text-4xl">Hanga Pitchfest 2023: 25 startups in boot camp set to showcase solutions</h2>
              <p class="text-navy font-gramatika text-md sm:text-base mt-2">The top 25 tech startups enrolled in the boot camp of the third edition of Hanga Pitchfest are set to pitch...</p>
              <p class="text-navy font-rockinsoda text-md sm:text-base mt-4">Nov 23 . THE NEW TIMES, Patrick Nzabonimpa</p>
            </div>
          </div>
        </div>
        <!-- Third Card -->
        <div class="bg-white">
          <div class="flex flex-col space-y-8">
            <img src="../assets/news-5.jpg" class="h-[270px] object-cover">
            <div class="h-full flex flex-col">
              <h2 class="text-navy font-rockinsoda text-3xl sm:text-3xl md:text-4xl">Solving the Organic Waste Problem - Cynthia Umutoniwabo |WIB Podcast E5</h2>
              <p class="text-navy font-gramatika text-md sm:text-base mt-2">On this episode of "What I'm Building," Here is what I learned from my conversation with Cynthia Umutoniwabo, CEO and co-founder of Loopa ; a groundbreaking agricultural startup.</p>
              <p class="text-navy font-rockinsoda text-md sm:text-base mt-4">Jan 24 . What I'm Building Podcast</p>
            </div>
          </div>
        </div>
      </div>

      <!-- Third Column -->
      <div class="flex flex-col w-full md:w-2/4 space-y-12">
        <!-- First Card -->
        <div class="bg-white">
          <div class="flex flex-col space-y-8">
            <img src="../assets/news-6.jpg" class="h-[270px] object-cover">
            <div class="h-full flex flex-col">
              <h2 class="text-navy font-rockinsoda text-3xl sm:text-3xl md:text-4xl">VIDEO: President Kagame Speaks: A Fireside Chat at Hanga Pitchfest 2023
                </h2>
              <p class="text-navy font-gramatika text-md sm:text-base mt-2">Watch the Youtube Video for more details... </p>
              <p class="text-navy font-rockinsoda text-md sm:text-base mt-4">Dec 9 . igihe</p>
            </div>
          </div>
        </div>
        <!-- Second Card -->
        <div class="bg-white">
          <div class="h-full flex flex-col">
            <h2 class="text-navy font-rockinsoda text-3xl sm:text-3xl md:text-4xl">How local start-up is leveraging tech to transform organic waste into fertiliser</h2>
            <p class="text-navy font-gramatika text-md sm:text-base mt-2">In March 2023, Cynthia Umutoniwabo participated in the third cohort of Jasiri Talent Investor (Simba)...</p>
            <p class="text-navy font-rockinsoda text-md sm:text-base mt-4">Dec 23 . THE NEW TIMES, Patrick Nzabonimpa</p>
          </div>
        </div>
        <!-- Third Card -->
        <div class="bg-white">
          <div class="flex flex-col space-y-8">
            <img src="../assets/news-7.jpg" class="h-[270px] object-cover">
            <div class="h-full flex flex-col">
              <h2 class="text-navy font-rockinsoda text-3xl sm:text-3xl md:text-4xl">Rwanda launches $2M facility to provide financial and technical support to agritech startups</h2>
              <p class="text-navy font-gramatika text-md sm:text-base mt-2">Rwanda has unveiled $2 million in funding for agriculture technology startups to provide them with financial and technical support...</p>
              <p class="text-navy font-rockinsoda text-md sm:text-base mt-4">Dec 23 . FARMERS REVIEW AFRICA, Zablon Oyugi</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Newsletter signup section -->
    <NewsLetter />

    <!-- Footer section -->
    <FooterPage />
  </div>
</template>

<script>
import SubNav from '@/components/SubNav.vue';
import NewsLetter from '@/components/NewsLetter.vue';
import FooterPage from '@/components/footerPage.vue';
export default {
  name: "NewsPage",
  components: {

    NewsLetter,
    SubNav,
    FooterPage
  }

}
</script>
