<template>
  <div>
    <SubNav />
    <!-- Main Header Section -->
    <div class="flex items-center justify-center text-navy font-rockinsoda text-5xl sm:text-7xl mt-20 sm:mt-40 text-center px-4">
      <div class="block">
        <p>WHERE WE SHARE OUR</p>
        <p class="sm:ml-16">STORIES & IDEAS</p>
      </div>
    </div>

    <!-- Category Buttons -->
    <div class="flex flex-wrap justify-center gap-2 sm:gap-4 mt-10 px-4">
      <label>
        <input type="radio" name="category" value="Fintech" class="hidden" v-model="selectedCategory" />
        <span
          :class="['inline-block px-4 py-2 border border-lightTangerine text-navy hover:bg-tangerine hover:text-white', selectedCategory === 'Fintech' ? 'bg-tangerine text-white' : 'border-tangerine text-navy']">
          ALL TOPICS
        </span>
      </label>
      <label>
        <input type="radio" name="category" value="Education" class="hidden" v-model="selectedCategory" />
        <span
          :class="['inline-block px-4 py-2 border border-lightTangerine text-navy hover:bg-tangerine hover:text-white', selectedCategory === 'Education' ? 'bg-tangerine text-white' : 'border-tangerine text-navy']">
          STARTUPS
        </span>
      </label>
      <label>
        <input type="radio" name="category" value="Software Development" class="hidden" v-model="selectedCategory" />
        <span
          :class="['inline-block px-4 py-2 border border-lightTangerine text-navy hover:bg-tangerine hover:text-white', selectedCategory === 'Software Development' ? 'bg-tangerine text-white' : 'border-tangerine text-navy']">
          WEEKLY ROUND-UPS
        </span>
      </label>
      <label>
        <input type="radio" name="category" value="Health" class="hidden" v-model="selectedCategory" />
        <span
          :class="['inline-block px-4 py-2 border border-lightTangerine text-navy hover:bg-tangerine hover:text-white', selectedCategory === 'Health' ? 'bg-tangerine text-white' : 'border-tangerine text-navy']">
          HANGA PITCH FEST
        </span>
      </label>
    </div>

    <!-- Blog Cards -->
    <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 p-4 mt-12 sm:mt-20 ml-4 sm:ml-12 mr-4 sm:mr-12">
      <div
        v-for="blog in blogs"
        :key="blog.id"
        class="relative overflow-hidden shadow-md cursor-pointer"
        @click="viewBlog(blog)"
      >
        <!-- Blog cover image -->
        <img
          :src="blog.image"
          alt="Blog Image"
          class="w-full h-[300px] sm:h-[400px] object-cover"
        />

        <!-- Blog title and description overlay -->
        <div
          class="absolute inset-0 text-white p-4 flex flex-col justify-end"
          style="background: linear-gradient(to top, rgba(1, 9, 48, 0.76) 0%, rgba(1, 9, 48, 0.76) 30%, rgba(1, 9, 48, 0.2) 70%);"
        >
          <button
            class="text-black bg-white absolute top-0 mt-4 font-gramatika text-md sm:text-lg p-1 sm:p-2 px-4 sm:px-6 w-fit">
            {{ blog.category }}
          </button>
          <h2 class="text-xl sm:text-3xl font-rockinsoda">{{ blog.title }}</h2>
          <p class="text-xl sm:text-3xl font-rockinsoda">{{ blog.episode }}</p>
          <p class="text-sm mt-2 font-grammatika">{{ blog.content.substring(0, 100) }}...</p>
          <div class="flex space-x-2 mt-2 text-sm sm:text-md">
            <p class="font-bold font-rockinsoda">{{ blog.date }} .</p>
            <p class="font-bold font-rockinsoda">{{ blog.author }}</p>
          </div>
        </div>
      </div>
    </div>

    <NewsLetter />
    <footerPage />
  </div>
</template>


<script>
import client from '../contentful'; 
import SubNav from '@/components/SubNav.vue';
import NewsLetter from '@/components/NewsLetter.vue';
import footerPage from '@/components/footerPage.vue';

export default {
  components:{
      SubNav,
      NewsLetter,
      footerPage
  },
  data() {
    return {
      blogs: [], // To store blogs from Contentful
    };
  },
  async created() {
    try {
      const response = await client.getEntries({ content_type: "blogPost" });
      this.blogs = response.items
        .filter((item) => item.fields) 
        .map((item) => ({
          id: item.sys.id,
          date:item.fields.date,
          category:item.fields.category,
          author:item.fields.author,
          episode:item.fields.episode,
          title: item.fields.title || "Untitled",
          image: item.fields.blogImage 
            ? `https:${item.fields.blogImage.fields.file.url}` 
            : null,
          content: item.fields.blogDesc || "No description available.",
        }));
    } catch (error) {
      console.error("Error fetching blogs:", error);
    }
  },
  methods: {
    viewBlog(blog) {
      this.$router.push({ name: 'BlogDetails', params: { id: blog.id } });
    },
  },
};
</script>

<style>
/* Styling for blog cards */
/*.relative {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.relative:hover {
  transform: scale(1.05);
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.2);
}*/
</style>
